import { QuestionFooter, IProps as IFooterProps } from "../../components/question-footer/QuestionFooter";
import { QuestionHeader } from "../../components/question-header/QuestionHeader";
import { ProgressBar } from "../../components/progress-bar/ProgressBar";
import { Constants } from "../../utils/Constants";
import React, { useState, useEffect } from 'react';
import useCustomStyle from "../../utils/hooks/useCustomeStyles";
import "./QuestionView.scss";
interface IProps {
    title: string;
    questionSubPage: JSX.Element;
    footer?: IFooterProps
    onMenuClick: any;
    handleStyleClick?: any;
    // questionMark?: boolean;
    // handleQuestionMark?: any;
    // handleQuestionIdk?: any;
}

interface Style {
    color: string;
    backgroundColor: string;
    fontSize: string,
}

export const QuestionView = (props: IProps) => {

    const ref = React.useRef<HTMLDivElement>(null);
    const [isOverflow, setIsOverflow] = useState(false);
    const [isDownArrow, setIsDownArrow] = useState(true);

    const [answersCount, setAnswersCount] = useState<number>(0);

    const [fontSizeHeader, setFontSizeHeader] = useState<string>('50px');
    const [fontSizeBody, setFontSizeBody] = useState<string>('40px');
    const [smallDevice, setSmallDevice] = useState<boolean>(window.matchMedia("(max-width: 600px)").matches);
    const selectedLanguageKey = localStorage.getItem(Constants.KEY_LANGUAGE) || "en";

    const translations : any = {
        "fr": {
            "thankYouTitle": "Merci pour vos réponses.",
            "thankYouSubtitle": "Elles viennent d'être envoyées au médecin. <br/>Rappelez bien au médecin que vous avez utilisé",
            "infoBox": "Le médecin vous appellera lorsque ce sera votre tour. Il est possible que certains patients arrivés après vous soient pris en charge avant vous, car ils nécessitent une prise en charge plus urgente.",
            "closeText": "Oui oui, vous pouvez fermer cette page maintenant !"
        },
        "en": {
            "thankYouTitle": "Thank you for your answers.",
            "thankYouSubtitle": "They have just been sent to the doctor. Please remind the doctor that you used",
            "infoBox": "The doctor will call you when it is your turn. Some patients who arrived after you may be taken care of before you, as they require more urgent attention.",
            "closeText": "Yes, you can close this page now!"
        },
        "es": {
            "thankYouTitle": "Gracias por sus respuestas.",
            "thankYouSubtitle": "Acaban de ser enviadas al médico. Recuerde al médico que utilizó",
            "infoBox": "El médico le llamará cuando sea su turno. Es posible que algunos pacientes que llegaron después de usted sean atendidos antes, ya que requieren atención más urgente.",
            "closeText": "¡Sí, puede cerrar esta página ahora!"
        },
        "it": {
            "thankYouTitle": "Grazie per le vostre risposte.",
            "thankYouSubtitle": "Sono state appena inviate al medico. Ricordate al medico che avete utilizzato",
            "infoBox": "Il medico vi chiamerà quando sarà il vostro turno. Alcuni pazienti arrivati dopo di voi potrebbero essere assistiti prima, in quanto necessitano di un'attenzione più urgente.",
            "closeText": "Sì, sì, potete chiudere questa pagina ora!"
        },
        "ar": {
            "thankYouTitle": "شكرًا على إجاباتكم.",
            "thankYouSubtitle": "تم إرسالها للتو إلى الطبيب. يرجى تذكير الطبيب بأنكم استخدمتم",
            "infoBox": "سيتصل الطبيب بكم عندما يحين دوركم. قد يتم رعاية بعض المرضى الذين وصلوا بعدكم قبلكم، لأنهم يحتاجون إلى رعاية أكثر إلحاحًا.",
            "closeText": "نعم، نعم، يمكنكم إغلاق هذه الصفحة الآن!"
        },
        "sq": {
            "thankYouTitle": "Faleminderit për përgjigjet tuaja.",
            "thankYouSubtitle": "Ato sapo iu dërguan mjekut. Ju lutemi kujtojini mjekut që keni përdorur",
            "infoBox": "Mjeku do t'ju telefonojë kur të vijë radha juaj. Disa pacientë që kanë ardhur pas jush mund të trajtohen para jush, pasi ata kërkojnë kujdes më urgjent.",
            "closeText": "Po, po, tani mund ta mbyllni këtë faqe"
        },
        "pt": {
            "thankYouTitle": "Obrigado pelas suas respostas.",
            "thankYouSubtitle": "Elas foram enviadas ao médico. Por favor, lembre o médico de que você utilizou",
            "infoBox": "O médico ligará para você quando chegar a sua vez. Alguns pacientes que chegaram depois de você podem ser atendidos antes, pois necessitam de atendimento mais urgente.",
            "closeText": "Sim, sim, pode fechar esta página agora!"
        },
        "ka": {
            "thankYouTitle": "გმადლობთ თქვენს პასუხებზე.",
            "thankYouSubtitle": "ისინი ექიმს ახლახან გაეგზავნა. გთხოვთ, ექიმს გაახსენოთ, რომ თქვენ გამოიყენეთ",
            "infoBox": "ექიმი დაგიკავშირდებათ, როდესაც თქვენი რიგი მოვა. შესაძლოა, ზოგიერთი პაციენტი, რომელიც თქვენ შემდეგ მივიდა, თქვენამდე მიიღოს მომსახურება, რადგან მათ უფრო სწრაფი დახმარება სჭირდებათ.",
            "closeText": "დიახ, დიახ, ახლა შეგიძლიათ დახუროთ ეს გვერდი!"
        },
        "ro": {
            "thankYouTitle": "Vă mulțumim pentru răspunsuri.",
            "thankYouSubtitle": "Acestea tocmai au fost trimise medicului. Vă rugăm să-i reamintiți medicului că ați utilizat",
            "infoBox": "Medicul vă va suna când va fi rândul dumneavoastră. Este posibil ca unii pacienți care au sosit după dumneavoastră să fie preluați înainte, deoarece necesită îngrijiri mai urgente.",
            "closeText": "Da, da, puteți închide această pagină acum!"
        },
        "ru": {
            "thankYouTitle": "Спасибо за ваши ответы.",
            "thankYouSubtitle": "Они только что были отправлены врачу. Пожалуйста, напомните врачу, что вы использовали",
            "infoBox": "Врач позвонит вам, когда подойдет ваша очередь. Возможно, некоторые пациенты, пришедшие после вас, будут приняты раньше, так как они нуждаются в более срочной помощи.",
            "closeText": "Да-да, вы можете закрыть эту страницу сейчас!"
        },
        "tr": {
            "thankYouTitle": "Cevaplarınız için teşekkür ederiz.",
            "thankYouSubtitle": "Cevaplarınız doktora yeni gönderildi. Lütfen doktora şu ürünü kullandığınızı hatırlatın:",
            "infoBox": "Doktor, sıramız geldiğinde sizi arayacaktır. Sizden sonra gelen bazı hastalar, daha acil müdahale gerektirdiği için sizden önce alınabilir.",
            "closeText": "Evet, evet, bu sayfayı şimdi kapatabilirsiniz!"
        }
    };
    useEffect(() => {
        if(smallDevice) {
                setFontSizeHeader('35px');
                setFontSizeBody('25px');
            }
    }, [])

    const styleHeader = useCustomStyle(
        'styleHeader',
        {
            color: 'white',
            backgroundColor: 'black',
            fontSize: fontSizeHeader
        });

    const styleBody = useCustomStyle(
        'styleBody',
        {
            color: 'white',
            backgroundColor: 'black',
            fontSize: fontSizeBody
        });

    const handleStyleClick = () => {
        styleHeader.handleClick();
        styleBody.handleClick();
    }

    // Detect overflow of div
    React.useLayoutEffect(() => {
        if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
            setIsOverflow(true)
        }
    })

    // Detect scroll to bottom of div
    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;
            if (scrollTop + clientHeight < scrollHeight) {
                setIsDownArrow(false);
            } else {
                setIsDownArrow(true);
            }
        }
    };

    let className;

    if (isOverflow) {
        className = "arrow-overflow";
        // Not display arrow when checkbox "Saisie text" is checked
        if (props.questionSubPage.props.question?.answer.other === undefined) {
            className = "arrow-overflow--none";
        }
    } else {
        className = "arrow-overflow--none";
    }

    let storedAnswers = localStorage.getItem(Constants.KEY_ANSWERS);
    let currentQuestionId: string;

    if (props.questionSubPage.props.question?.answer.other !== undefined) { // If not "Saisie text"
        currentQuestionId = props.questionSubPage.props.question.id;
    }
    useEffect(() => {
        if (storedAnswers) {
            setAnswersCount((JSON.parse(storedAnswers).length));

            if (currentQuestionId === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb') {
                setAnswersCount(40);
            }
        }
    }, []);


    if(props.footer){
        props.footer.next = props.questionSubPage.props?.question?.id !== '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb' && props.questionSubPage.props?.question?.id !== 'e7b54df9-9b53-4cb6-9b16-7b5f10b84634';

    }
    if(props.questionSubPage.props?.question?.id === '645f19a0-ff20-4313-924b-d06e51c73ce4'){
        //alert('in');

        // redirect neeed to be there

        return (<></>);
    }

    return (
        <div className={"page"}>
            { (   props.questionSubPage.props?.question?.id === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb' || props.questionSubPage.props?.question?.id === 'e7b54df9-9b53-4cb6-9b16-7b5f10b84634') && (
                <div className={"question centered-question"} style={{ background: "white" }}>
                    <ProgressBar questionsCompletedCount={answersCount} show={true} />
                    <div className="thank-you-container" style={{height: "100vh"}}>
                        <h2 className="thank-you-title">{translations[selectedLanguageKey].thankYouTitle}</h2>
                        <div
                            className={"thank-you-subtitle"}
                            dangerouslySetInnerHTML={{
                                __html: translations[selectedLanguageKey].thankYouSubtitle
                            }}
                        ></div>
                        <img
                            src={process.env.PUBLIC_URL + "/logo-couleur.jpg"}
                            alt="Marti Logo"
                            className="marti-logo"
                        />
                        <div className="info-box">
                            {translations[selectedLanguageKey].infoBox}
                        </div>
                        <div className="close-text">
                            {translations[selectedLanguageKey].closeText}
                        </div>
                        {/*<div className="close-button" onClick={() => window.history.back()}>*/}
                        {/*    <img*/}
                        {/*        src={process.env.PUBLIC_URL + "/pictos/fleche-nobg.svg"}*/}
                        {/*        alt="Close"*/}
                        {/*        style={{ transform: "rotate(180deg)", width: "50px", height: "50px" }}*/}
                        {/*    />*/}

                        {/*</div>*/}
                        <QuestionFooter {...props.footer} />
                    </div>
                </div>
            )}

            {props.questionSubPage.props?.question?.id !=='e7b54df9-9b53-4cb6-9b16-7b5f10b84634' && props.questionSubPage.props?.question?.id !=='5b7145a4-5fec-49b2-b8a1-a08f708cf6bb' && (
            <div className={"question"}>
                <ProgressBar questionsCompletedCount={answersCount} show={true} />


                <QuestionHeader
                    title={props.title}
                    onMenuClick={props.onMenuClick}
                    handleStyleClick={() => handleStyleClick()}
                    newStyle={styleHeader.style} />


                <div
                    className={'question-container'} ref={ref} onScroll={() => onScroll()}
                    style={styleBody.style}
                >

                    <div className={className}>
                        <img src={process.env.PUBLIC_URL + "/pictos/fleche.svg"}
                            style={isDownArrow ? { transform: 'rotate(90deg)' } : { transform: 'rotate(270deg)' }} />
                    </div>

                        {props.questionSubPage}
                    {/*{props.questionSubPage.props?.question?.title !== 'Le questionnaire est presque terminé. Si vous n\'avez pas pu exprimer tous les symptômes de votre enfant, vous pourrez le faire à la fin, puis directement au médecin.' && (*/}
                    {/*    <>*/}
                    {/*        {props.questionSubPage}*/}
                    {/*    </>*/}
                    {/*)}*/}

                </div>
                <QuestionFooter
                    {...props.footer}/>
            </div>
            )}
        </div>
    )
}
