import {BaseController} from "./BaseController";
import {Answer} from "../models/Answer";
import {Option} from "../models/Option";
import {OptionController} from "./OptionController";
import {ApiAnswer} from "../models/api/ApiAnswer";
import {Interval} from "../models/Interval";
import {ClickableImage} from "../models/ClickableImage";
import {StoredAnswer} from "../models/StoredAnswer";
import {ApiLanguage} from "../models/api/ApiLanguage";

export class AnswerController extends BaseController {
    optionController = new OptionController();

    getByUrl(url: string): Promise<Answer> {
        console.log(url);
        return super.get(url).then(async res => {
            let data = (res as ApiAnswer).data;
            let answer: Answer = new Answer(data.type);

            if (data.type === "paragraph--answer_check_box") {
                answer.multiValued = data.attributes.field_answer_check_box_ma;
                answer.other = data.attributes.field_answer_check_box_other;

                if (data.relationships.field_answer_check_box_values) {
                    console.log('options', data.relationships.field_answer_check_box_values.links.related.href);
                    await this.optionController.getList(data.relationships.field_answer_check_box_values.links.related.href).then(res2 => {
                        answer.options = res2;
                    });
                }

                if (answer.other) {
                    answer.options?.push(new Option("other", data.attributes.field_label_other, data.attributes.field_placeholder_other));
                }
            } else if (data.type === "paragraph--yes_no_question") {
                let options: Option[] = [];

                let yes = new Option("o", data.attributes.field_label_yes ? data.attributes.field_label_yes : "Oui");
                if (data.relationships.field_yes_no_question_next_yes.data) {
                    yes.next = data.relationships.field_yes_no_question_next_yes.data.id;
                }
                options.push(yes);

                let no = new Option("n", data.attributes.field_label_no ? data.attributes.field_label_no : "Non");
                if (data.relationships.field_yes_no_question_next_no.data) {
                    no.next = data.relationships.field_yes_no_question_next_no.data.id;
                }
                options.push(no);

                answer.options = options;
            } else if (data.type === "paragraph--answer_number") {
                if (data.relationships.field_answer_number_conditions) {
                    await this.optionController.getIntervalList(data.relationships.field_answer_number_conditions.links.related.href).then(res2 => {
                        answer.options = res2;
                    });
                }

                if (data.attributes.field_answer_number_unit) {
                    answer.unit = data.attributes.field_answer_number_unit;
                }

                if (data.attributes.field_answer_number_gauge) {
                    answer.gauge = data.attributes.field_answer_number_gauge;
                    answer.value = "37";
                } else if (answer.options) {
                    answer.value = Math.min(...answer.options?.map(o => (o as Interval).min))
                }
            } else if (data.type === "paragraph--clickable_image") {
                (answer as ClickableImage).map = data.attributes.field_clickable_image_imc;
            } else if (data.type === "paragraph--answer_time") {
                answer.date = data.attributes.field_date;
                answer.time = data.attributes.field_time;
                answer.dateThenTime = data.attributes.field_date_and_after_hour;
            } else if (data.type === "paragraph--question_no_reponse") {
                if(data.attributes.field_question_content?.processed !== undefined) {
                    answer.title = data.attributes.field_question_content.processed;
                } else {
                    answer.title = "";
                }
            }

            return answer;
        });
    }

    postAnswers(id: number, data: StoredAnswer[], start: string, langue: ApiLanguage, category: string): Promise<any> {
        return super.post("api/cr", {id, data, start, langue, category});
    }
}
