import {Component} from "react";
import {Question} from "../../models/Question";
import {QuestionController} from "../../controllers/QuestionController";
import "./QuestionStyles.scss"
import {QuestionView} from "./QuestionView";
import {IProps as IFooterProps} from "../../components/question-footer/QuestionFooter";
import {Constants} from "../../utils/Constants";
import {QuestionNumberPage} from "./number/QuestionNumberPage";
import {QuestionThermometerPage} from "./thermometer/QuestionThermometerPage";
import {QuestionYesNoPage} from "./yes-no/QuestionYesNoPage";
import {QuestionCheckboxesPage} from "./checkboxes/QuestionCheckboxesPage";
import {Interval} from "../../models/Interval";
import {QuestionOtherView} from "./other/QuestionOtherView";
import {MenuPage} from "../menu/MenuPage";
import {LoadingView} from "../loading/LoadingView";
import {StoredAnswer} from "../../models/StoredAnswer";
import {AnswerController} from "../../controllers/AnswerController";
import Cookies from "universal-cookie";
import FileSaver from 'file-saver';
import {QuestionTimePage} from "./time/QuestionTimePage";
import {ApiLanguage} from "../../models/api/ApiLanguage";
import {QuestionNoResponsePage} from "./no-response/QuestionNoResponsePage";
import {QuestionFreeTextPage} from "./free-text/QuestionFreeTextPage";
import QuestionClickableImagePage from "./clickable-image/QuestionClickableImagePage";


interface IProps {
    match?: {
        params: {
            id?: string;
        }
    }
}

interface IState {
    // questionMark: boolean;
    // handleQuestionMark: any;
    // handleQuestionIdk: any;
    question: Question;
    footer: IFooterProps;
    other: boolean;
    menu: boolean;
    submitting: boolean;
    lastQuestion: boolean;
}

export class QuestionPage extends Component<IProps, IState> {
    questionController = new QuestionController();
    answerController = new AnswerController();

    componentDidMount() {


        if (this.props.match?.params.id) {
            this.questionController.getById(this.props.match.params.id)
                .then(question => {
                    console.log(question);
                    this.onQuestionLoaded(question);
                });
        } else {
            window.location.href = "/";
        }
    }

    render() {
        if (this.state === null || this.state.question === null || this.state.submitting) {
            return <LoadingView/>
        } else if (this.state.menu) {
            return <MenuPage onMenuClick={this.onMenuClick.bind(this)}/>
        } else {
            let questionSubPage: JSX.Element = <div className={"question-content"}/>;

            if (this.state.other) {
                let other = this.state.question.answer?.options?.find(o => o.id === "other");
                questionSubPage = <QuestionOtherView onChange={this.onOtherChange.bind(this)}
                                                     close={this.closeOther.bind(this)}
                                                     value={other?.title}
                                                     placeholder={other?.placeholder}/>
            } else if (this.state.question.answer?.type === "paragraph--answer_check_box") {
                questionSubPage = <QuestionCheckboxesPage question={this.state.question}
                                                          setQuestion={this.setQuestion.bind(this)}
                                                          openOther={this.openOther.bind(this)}/>
            } else if (this.state.question.answer?.type === "paragraph--yes_no_question") {
                questionSubPage = <QuestionYesNoPage question={this.state.question}
                                                     setQuestion={this.setQuestionAndNext.bind(this)}/>
            } else if (this.state.question.answer?.type === "paragraph--answer_number") {
                if (this.state.question.answer.gauge) {
                    questionSubPage = <QuestionThermometerPage question={this.state.question}
                                                               setQuestion={this.setQuestion.bind(this)}/>
                } else {
                    questionSubPage = <QuestionNumberPage question={this.state.question}
                                                          setQuestion={this.setQuestion.bind(this)}/>
                }
            } else if (this.state.question.answer?.type === "paragraph--clickable_image") {
                questionSubPage = <QuestionClickableImagePage question={this.state.question}
                                                              setQuestion={this.setQuestion.bind(this)}/>;
            } else if (this.state.question.answer?.type === "paragraph--answer_time") {
                questionSubPage = <QuestionTimePage question={this.state.question}
                                                    setQuestion={this.setQuestion.bind(this)}/>
            } else if (this.state.question.answer?.type === "paragraph--question_no_reponse") {
                questionSubPage = <QuestionNoResponsePage question={this.state.question}/>;
            } else if (this.state.question.answer?.type === "paragraph--free_text") {
                let other = this.state.question.answer?.options?.find(o => o.id === "other");
                questionSubPage = <QuestionFreeTextPage question={this.state.question}
                                                        setQuestion={this.setQuestion.bind(this)}
                                                        placeholder={other?.placeholder}/>;
            }

            return <QuestionView title={this.state.question.title}
                //  questionMark={this.state.questionMark}
                //  handleQuestionMark={this.state.handleQuestionMark}
                //  handleQuestionIdk={this.state.handleQuestionIdk}
                                 questionSubPage={questionSubPage}
                                 footer={this.state.footer}
                                 onMenuClick={this.onMenuClick.bind(this)}/>
        }
    }

    private setQuestion(question: Question) {
        this.setState({question, footer: {...this.state.footer, next: question.answer?.value !== undefined}});
    }

    private setQuestionAndNext(question: Question) {
        this.setQuestion(question);
        this.onNextClick();
    }

    private onQuestionLoaded(question: Question) {
        let answer = question.answer;
        if (answer) {
            let storedAnswers: any = localStorage.getItem(Constants.KEY_ANSWERS);
            if (storedAnswers) {
                storedAnswers = JSON.parse(storedAnswers);

                let storedAnswer = (storedAnswers as StoredAnswer[]).find(a => a.id === question.id);
                if (storedAnswer) {
                    answer.value = storedAnswer.value;

                    if (answer.type === "paragraph--answer_check_box" && answer.options) {
                        let options = answer.options;

                        if (answer.multiValued) {
                            if (Array.isArray(answer.value)) {
                                if ((answer.value as string[]).some(v => !options.map(o => o.id).includes(v))) {
                                    let other = options.find((o: any) => o.id === "other");
                                    if (other) {
                                        let otherTitle = (storedAnswer.value as string[]).find(v => !options.map((o: any) => o.id).includes(v));
                                        if (otherTitle !== undefined) {
                                            other.title = otherTitle;
                                            answer.value = [...(answer.value as string[]).filter(v => options.map((o: any) => o.id).includes(v)), "other"];
                                        }
                                    }
                                }
                            } else if (!options.map((o: any) => o.id).includes(answer.value as string)) {
                                let other = options.find((o: any) => o.id === "other");
                                if (other) {
                                    other.title = storedAnswer.value as string;
                                    answer.value = storedAnswer.value;
                                }
                            }

                        } else if (!options.map((o: any) => o.id).includes(answer.value as string)) {
                            let other = options.find((o: any) => o.id === "other");
                            if (other) {
                                other.title = storedAnswer.value as string;
                                answer.value = storedAnswer.value;
                            }
                        }
                    }
                }
            }
        }

        this.setState({
            // questionMark: answer?.type !== "paragraph--question_no_reponse",
            // handleQuestionMark: this.onQuestionMarkClick.bind(this),
            // handleQuestionIdk: this.onIdkQuestion.bind(this),
            question,
            footer: {
                visible: true,
                previous: true,
                handlePrevious: this.onPreviousClick.bind(this),
                next: (answer && answer.value !== undefined) || answer?.type === "paragraph--question_no_reponse",
                handleNext: this.onNextClick.bind(this),
                handleQuestionMark: this.onQuestionMarkClick.bind(this),
                handleQuestionIdk: this.onIdkQuestion.bind(this),
                questionMark: answer?.type !== "paragraph--question_no_reponse",
            }
        });
    }

    private onPreviousClick() {
        // On retire la question de la liste des réponses enregistrées, si elle s'y trouve
        let answers: StoredAnswer[];
        let storedAnswers = localStorage.getItem(Constants.KEY_ANSWERS);
        if (storedAnswers) {
            answers = JSON.parse(storedAnswers);
        } else {
            answers = [];
        }
        let filteredAnswers = answers.filter(a => a.id !== this.state.question.id)
        localStorage.setItem(Constants.KEY_ANSWERS, JSON.stringify(filteredAnswers));

        window.history.back();
    }

    private onIdkQuestion() {
        const questionToEdit = this.state.question;
        if (questionToEdit.answer !== undefined) {
            questionToEdit.answer.value = "L'utilisateur n'a pas su répondre";
            this.setState({question: questionToEdit})
        }
        this.onQuestionMarkClick();
    }

    /**
     * Je ne sais pas handler
     * @private
     */
    private onQuestionMarkClick() {
        const next = this.saveAnswerToSession(this.state.question);


        if(next){
            if (next === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb') {
                this.submitCr(next); // this willredirect
            } else {
                window.location.href = "/question/" + next;
            }
            return;
        }

        if(!next){
            if (this.state.question.next) {
                // next  question is merci we submit
                if (this.state.question.next === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb') {
                    this.submitCr(this.state.question.next);
                    return;
                } else {
                    window.location.href = "/question/" + this.state.question.next;
                    return;
                }
            } else {
                window.location.href = "/";

            }
        }


    }

    private onNextClick() {
        console.log('onNextClick()');
        // HOT FIX nexxt question
        if (this.state.question.answer && this.state.question.answer?.value === undefined) {
            this.state.question.answer.value = "L'utilisateur n'a pas su répondre";
        }

        let answer = this.state.question.answer;


        if (answer) {

            let next : any = this.saveAnswerToSessionAndGetNext(this.state.question);
            console.log(next);
           // return;
            if (next) {
                if (this.state.question.id === '87898cb3-f3e3-4448-b873-225ad9bca772') {
                    this.submitCr(next); // this willredirect
                    return;
                }

                window.location.href = "/question/" + next;
                return;
            } else {
                window.location.href = "/";
            }
        }


    }

    private onMenuClick() {
        this.setState({menu: !this.state.menu});
    }

    private openOther() {
        this.setState({other: true, footer: {...this.state.footer, visible: false}});
    }

    private closeOther() {
        this.setState({other: false, footer: {...this.state.footer, visible: true}});
    }

    private onOtherChange(event: any) {
        let question = this.state.question;
        let options = question.answer?.options;
        if (options) {
            let option = options.find(o => o.id === "other");
            if (option) {
                option.title = event.target.value;
                this.setState({question});
            }
        }
    }

    private saveAnswerToSession(question: Question) {
        return this.saveAnswerToSessionAndGetNext(question);
    }

    private findFirstNotAnsweredItem(items: any[]): any | null {
        for (const item of items) {
            // If the item hasn't been answered, return it immediately.
            if (!item.answered) {
                return item;
            }
            // If the item has nested items, search them recursively.
            if (item.nexts && Array.isArray(item.nexts)) {
                const found = this.findFirstNotAnsweredItem(item.nexts);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

// Helper: Recursively collects all leaf nodes (terminal items) from the tree.
    private getAllLeaves(items: any[]): any[] {
        let leaves: any[] = [];
        for (const item of items) {
            // If no nexts or an empty nexts array, it's a leaf.
            if (!item.nexts || item.nexts.length === 0) {
                leaves.push(item);
            } else {
                // Otherwise, add leaves from the nested nexts.
                leaves.push(...this.getAllLeaves(item.nexts));
            }
        }
        return leaves;
    }

    private findNextNotAnsweredLeaf(rootItems: any[], currentLeaf?: any): any | null {
        const leaves = this.getAllLeaves(rootItems);
        let startIndex = 0;
        if (currentLeaf) {
            // Find the index of the current leaf.
            const idx = leaves.findIndex(item => item === currentLeaf);
            if (idx >= 0) {
                startIndex = idx + 1;
            }
        }
        // Return the first not answered leaf starting from startIndex.
        for (let i = startIndex; i < leaves.length; i++) {
            if (!leaves[i].answered) {
                return leaves[i];
            }
        }
        return null;
    }
    private getFirstNonAnsweredLeafFromSpecial(specialData: any): string | undefined {
        for (const category in specialData) {
            if (specialData.hasOwnProperty(category)) {
                const leaves = specialData[category];
                if (Array.isArray(leaves)) {
                    const firstNonAnswered = leaves.find((leaf: any) => !leaf.answered);
                    if (firstNonAnswered) {
                        return firstNonAnswered;
                    }
                }
            }
        }
        return undefined;
    }

    private markAnsweredIfChildrenDone(item: any) {
        if (!item.nexts || item.nexts.length === 0) {
            return item.answered; // une feuille => return son état
        }

        const allChildrenAnswered = item.nexts.every((child: any) => this.markAnsweredIfChildrenDone(child));
        if (allChildrenAnswered) {
            item.answered = true;
            return true;
        }

        return false;
    }

    private saveAnswerToSessionAndGetNext(question: Question): string | undefined {
        console.trace('Stack trace:');
        let next;
        let answer = question.answer;
        console.log(answer);

        if ((answer && answer.value) || (answer && answer.value === 0)) {
            let value = answer.value;

            let options : any = answer.options;

            console.log(answer);

            if (options) {
                if (answer.type === "paragraph--answer_check_box") {
                    const optionsWithSphere = options.filter((option: any) => option.sphere !== null && option.sphere != undefined);
                    console.log("Options with sphere:", optionsWithSphere);

                    const hasSphere = optionsWithSphere.length > 0;

                    if (answer.multiValued && hasSphere) {
                        console.log('in multivatued sphere', hasSphere);
                        console.log('in multivatued sphere', options);
                        // Retrieve the list of sphere records from localStorage,
                        // or initialize an empty array if nothing is stored yet.
                        let sphereRecords: any = JSON.parse(localStorage.getItem('answeredSpheres') || '{}');
                        console.log(sphereRecords);
                        const selectedIds = Array.isArray(answer.value) ? answer.value : [answer.value];
                        // For each option in the answer's options that has a sphere,
                        // ensure that an entry exists in our sphereRecords list.
                        options.forEach((option:any) => {
                            if (option.sphere && selectedIds.includes(option.id)) {
                                // If there is not yet an array for this sphere, create one.
                                if (!sphereRecords[option.sphere]) {
                                    sphereRecords[option.sphere] = [];
                                }

                                // Push the option record into the array for this sphere.
                                sphereRecords[option.sphere].push({
                                    questionId: option.next,
                                    answered: false,
                                    title: option.title
                                });
                                sphereRecords.next = question.next;
                            }
                        });


                        // Save the updated sphereRecords back to localStorage.
                        localStorage.setItem('answeredSpheres', JSON.stringify(sphereRecords));

                        console.log('sphereRecords',sphereRecords);
                        // Determine which sphere option should drive the next question.
                        // We pick the first option (from the options list) that has a sphere
                        // and for which the corresponding record is still unanswered.
                        const pendingOption = options.find((option: any) => {
                            const sphere = option.sphere;
                            const nextId = option.next;
                            if (!sphere || !nextId) return false;

                            const records = sphereRecords[sphere] || [];

                            // ✅ We only want the ones that are in the sphere and still unanswered
                            return records.some((record: any) =>
                                record.questionId === nextId && record.answered === false
                            );
                        });
                        console.log('pendingOption sss', pendingOption);

                        if (pendingOption) {
                            // If a pending option is found, set next to its question id.
                            next = pendingOption.next;
                        } else {
                            // Otherwise, fallback to the default next question of the current question.
                            next = question.next;
                        }
                    }
                    // cas des non sphere et question multiples normal
                    if(!hasSphere){
                        if (answer.multiValued) {

                            const currentQuestionDataLocalStorage = localStorage.getItem('currentQuestionData');
                            let currentQuestionData: any = {};

                            options = options.filter((o:any) => (value as string[]).includes(o.id));



                            if(!currentQuestionDataLocalStorage){
                                currentQuestionData = {
                                    questionEncours: question.id,
                                    titleQuestion: question.title,
                                    answered: false,
                                    next: question.next,
                                    nexts: options.map((option: any) => ({
                                        titleOption: option.title,
                                        question: option.next,
                                        //question: option.question?.id,

                                    })),
                                };
                            }
                            if (currentQuestionDataLocalStorage) {
                                currentQuestionData = JSON.parse(currentQuestionDataLocalStorage);
                                // la question en cours est tjr la dernière multi value question.
                                console.log('question en cours', currentQuestionData.questionEncours);
                                currentQuestionData.questionEncours = question.id;
                                const matchingItem = this.findMatchingItemRecursive(currentQuestionData.nexts, currentQuestionData.questionEncours);
                                if(matchingItem){
                                    matchingItem.titleQuestion = question.title;
                                    matchingItem.question = question.id;
                                    matchingItem.answered = false;
                                    matchingItem.nexts =  options.map((option: any) => ({
                                        titleOption: option.title,
                                        question: option.next,
                                        //question: option.id,

                                    }));
                                    matchingItem.next = question.next;
                                }



                            }

                            console.log('NOUVEAUOptions filtrées sauvegardées avec leurs nexts:', currentQuestionData);

                            localStorage.setItem('currentQuestionData', JSON.stringify(currentQuestionData));


                            if (options.length > 0) {
                                value = value as string[];
                                console.log('question.next',options);
                                if (value.includes("other")) {
                                    value = [...value.filter(v => options?.map((o : any) => o.id).includes(v) && v !== "other"),
                                        options.find((o: any) => o.id === "other")?.title as string];
                                }
                                else {

                                    // dans tout les cas il va a la next, mais si la next c'est une question de sub

                                    // parcours fini on
                                    //next = question.next;
                                    // on va tjr au next de option 1
                                    next = options[0].next;
                                    // GO TO next question of
                                    // const firstUnanswered = currentQuestionData.nexts.find(option => !option.answered);
                                    // if (firstUnanswered) {
                                    //     next = firstUnanswered.next;
                                    // } else {
                                    //     next = question.next;
                                    // }

                                }
                            }
                            else {
                                next = question.next;
                            }
                        }
                        else if (value === "other") {
                            value = options.find((o: any) => o.id === "other")?.title as string;
                            next = question.next;
                        }
                        else {
                            let option = options.find((o: any) => o.id === value);
                            if (option) {
                                next = option.next;
                            }
                        }
                    }

                }
                else if (answer.type === "paragraph--answer_number") {
                    let option = (options as Interval[]).find(o => o.min <= value && o.max >= value);
                    if (option) {
                        next = option.next;
                    }
                }
                else if (answer.type === "paragraph--free_text") {
                    next = question.next;
                }
                else if (answer?.type === "paragraph--clickable_image") {
                    if (Array.isArray(answer.value)) {
                        next = answer.value![0];
                    }
                }
                else {
                    let option = options.find((o: any) => o.id === value);
                    if (option) {
                        next = option.next;
                    }
                }
            }
            else {
                if (answer?.type === "paragraph--clickable_image") {
                    if (Array.isArray(answer.value)) {
                        next = answer.value![0];
                    } else next = question.next;
                } else {
                    next = question.next;
                }
            }

            let answers: StoredAnswer[];

            let storedAnswers = localStorage.getItem(Constants.KEY_ANSWERS);
            if (storedAnswers) {
                answers = JSON.parse(storedAnswers);
            } else {
                answers = [];
            }

            let storedAnswer = answers.find(a => a.id === this.state.question.id);
            if (storedAnswer) {
                storedAnswer.value = value;
            } else {
                storedAnswer = new StoredAnswer(this.state.question.id, value);
                answers.push(storedAnswer);
            }

            localStorage.setItem(Constants.KEY_ANSWERS, JSON.stringify(answers));



        }

        if (answer?.type === "paragraph--question_no_reponse"&& next === undefined) {
            next = question.next;
        }
        // FIX empty next on yes no
        if (answer?.type === "paragraph--yes_no_question" && next === undefined) {
            next = question.next;
        }
        if(answer?.type === "paragraph--answer_check_box" && next === undefined){
            next = question.next;
        }

        // question spéicale
        console.log('question', question);
        console.log('question next', question.next);
        if(question.next === '645f19a0-ff20-4313-924b-d06e51c73ce4'){

            console.log("NEXT IS SPECIAL QUESTION !!!!")
            const currentQuestionDataLocalStorage = localStorage.getItem('currentQuestionData');


            if (currentQuestionDataLocalStorage) {
                const data = JSON.parse(currentQuestionDataLocalStorage);
                console.log('local storage', data);
                const currentLeaf = this.findNextNotAnsweredLeaf(data.nexts);
                if (currentLeaf) {
                    console.log('Current leaf (first not answered):', currentLeaf);
                    // Mark it as answered.
                    currentLeaf.answered = true;
                    this.markAnsweredIfChildrenDone(data);

                    // 2. Now find the next terminal not answered leaf after the current one.
                    const nextLeaf = this.findNextNotAnsweredLeaf(data.nexts, currentLeaf);
                    if (nextLeaf) {
                        console.log('Next leaf (first not answered after current):', nextLeaf);
                        // Update questionEncours with the question of the next leaf.
                        next = nextLeaf.question;
                    } else {

                        console.log('No more not answered leaf items available. ');
                        if(data.next === '645f19a0-ff20-4313-924b-d06e51c73ce4' || data.answered) {
                            const sphere = localStorage.getItem('answeredSpheres');
                            if (sphere) {
                                const dataSphere = JSON.parse(sphere);
                                console.log('sphere', dataSphere);
                                const leaf: any = this.getFirstNonAnsweredLeafFromSpecial(dataSphere);
                                console.log('current leaf', leaf);
                                leaf.answered = true;
                                const nextLeaf: any = this.getFirstNonAnsweredLeafFromSpecial(dataSphere);
                                if (nextLeaf) {
                                    console.log('next leaf', nextLeaf);
                                    next = nextLeaf.questionId;
                                }
                                if (!nextLeaf) {
                                    console.log('NO MORE SPHERE LEAF');
                                    next = dataSphere.next;
                                    // HERE REDIRECT TO NEXT OF THE ADULT QUESTION
                                }
                                localStorage.setItem('answeredSpheres', JSON.stringify(dataSphere));
                            }
                        }
                        else {
                            next = data.next;
                        }
                    }
                }
                else {
                    console.log('No not answered leaf items found HERE???.', data.next);
                    if(data.next === '645f19a0-ff20-4313-924b-d06e51c73ce4' || data.answered) {
                        const sphere = localStorage.getItem('answeredSpheres');
                        if(sphere){
                            const dataSphere = JSON.parse(sphere);
                            console.log('sphere', dataSphere);
                            const leaf: any = this.getFirstNonAnsweredLeafFromSpecial(dataSphere);
                            console.log('current leaf', leaf);
                            leaf.answered = true;
                            const nextLeaf: any = this.getFirstNonAnsweredLeafFromSpecial(dataSphere);
                            if(nextLeaf){
                                console.log('next leaf', nextLeaf);
                                next = nextLeaf.questionId;
                            }
                            if(!nextLeaf){
                                console.log('NO MORE SPHERE LEAF');
                                next = dataSphere.next;
                            }
                            localStorage.setItem('answeredSpheres', JSON.stringify(dataSphere));
                        }
                    }
                    else {
                        next = data.next;
                    }



                }

                localStorage.setItem('currentQuestionData', JSON.stringify(data));

            }

        }


        return next ?? question.next ;
    }

    private findMatchingItemRecursive(items: any[], questionEncours: string): any | null {
        for (const item of items) {
            if (item.question === questionEncours) {
                return item;
            }
            if (item.nexts && Array.isArray(item.nexts)) {
                const found = this.findMatchingItemRecursive(item.nexts, questionEncours);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    private findLastNotAnsweredItem(items: any[]): any | null {
        let lastNotAnswered = null;
        for (const item of items) {
            // If the item is not answered, update the candidate
            if (!item.answered) {
                lastNotAnswered = item;
            }
            // Recursively check nested items
            if (item.nexts && Array.isArray(item.nexts)) {
                const candidate = this.findLastNotAnsweredItem(item.nexts);
                if (candidate) {
                    lastNotAnswered = candidate;
                }
            }
        }
        return lastNotAnswered;
    }


    private submitCr(lastQuestionId: string) {
        let id = new Cookies().get(Constants.KEY_COOKIE);
        let data = JSON.parse(localStorage.getItem(Constants.KEY_ANSWERS) as string);
        let start = localStorage.getItem(Constants.KEY_START) as string;
        let langue: ApiLanguage = {
            name: localStorage.getItem(Constants.NAME_LANGUAGE) as string,
            code: localStorage.getItem(Constants.KEY_LANGUAGE) as string,
        }
        let category = localStorage.getItem(Constants.KEY_CATEGORY) as string;
        this.setState({submitting: true});
        console.log(langue)
        this.answerController.postAnswers(id, data, start, langue, category).then(res => {
            // if (res.sent) {
            //   const blob = new Blob([res], {type: 'application/pdf'});
            //   //FileSaver.saveAs(blob, "Compte-rendu - " + start + " - " + id);
            //

            // if(lastQuestionId){
            //   window.location.href = "/question/" + lastQuestionId;
            // } else {
            //   window.location.href = "/";
            // }
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            window.location.href = "/question/" + lastQuestionId;
            // always set the submitting state to false, regardless of success or failure
            this.setState({submitting: false});
        });
    }
}
