export class Option {
    id: string;
    title?: string;
    next?: string;
    placeholder?: string;
    picture?: string;
    pictureAlt?: string;
    sphere?: string;

    constructor(id: string, title?: string, placeholder?: string, sphere?: string) {
        this.id = id;
        this.title = title;
        this.placeholder = placeholder;
        this.sphere = sphere;
    }
}
